<template>

    <form action="#" @submit.prevent="submit" id="contact-form" class="nice-form nice-form_validation" novalidate>

        <div class="nice-form__row">

            <div class="nice-form__item nice-form__item_33">
                <label for="username" class="nice-form__label">{{$t('formContactUs.name.label')}}</label>
                <input type="text" id="username" name="Name" v-model="name" @input="clearErrors"
                       :placeholder="$t('formContactUs.name.placeholder')" class="nice-form__input" required>
            </div><!-- /nice-form__item -->

            <div class="nice-form__item nice-form__item_33">
                <label for="useremail" class="nice-form__label">{{$t('formContactUs.email.label')}}</label>
                <input type="email" id="useremail" name="Email" v-model="email" @input="clearErrors"
                       :placeholder="$t('formContactUs.email.placeholder')" class="nice-form__input" required>
            </div><!-- /nice-form__item -->

            <div class="nice-form__item nice-form__item_33">
                <label for="cont" class="nice-form__label">{{$t('formContactUs.cooperation.label')}}</label>
                <v-select
                        @input="setSelected"
                        inputId="cont"
                        :options="cooperationOptions"
                        :placeholder="$t('formContactUs.cooperation.placeholder')"
                        :clearable=false
                        :searchable=false
                        :components="{OpenIndicator}"
                        v-model="user_type"
                />
            </div><!-- /nice-form__item -->

        </div><!-- /nice-form__row -->

        <div class="nice-form__row">

            <div class="nice-form__item">
                <label for="message" class="nice-form__label">{{$t('formContactUs.message.label')}}</label>
                <textarea name="Message" id="message" v-model="question" @input="clearErrors"
                          :placeholder="$t('formContactUs.message.placeholder')" class="nice-form__text" required></textarea>
            </div><!-- /nice-form__item -->

        </div><!-- /nice-form__row -->

        <div class="nice-form__row nice-form__row_submit">

            <div class="nice-form__item">
                <button type="submit" class="btn"><span>{{$t('formContactUs.submit')}}</span></button>
            </div><!-- /nice-form__item -->

        </div><!-- /nice-form__row -->

        <div v-if="errors.length" class="nice-form__row nice-form__errors">
            <p class="nice-form__response_error">
                <b>{{$t('formMessages.errors')}}</b>
            </p>
            <ul>
                <li v-if="userTypeError">{{$t('formMessages.emptyType')}}</li>
                <li v-if="emptyNameError">{{$t('formMessages.emptyName')}}</li>
                <li v-if="incorrectNameError">{{$t('formMessages.incorrectName')}}</li>
                <li v-if="emptyEmailError">{{$t('formMessages.emptyEmail')}}</li>
                <li v-if="incorrectEmailError">{{$t('formMessages.incorrectEmail')}}</li>
                <li v-if="emptyQuestionError">{{$t('formMessages.emptyQuestion')}}</li>
            </ul>
        </div><!-- /nice-form__row -->

        <div v-if="this.response" class="nice-form__row nice-form__response">
            <p v-if="this.success" class="nice-form__response_success">
                <b>{{$t('formMessages.success')}}</b>
            </p>
        </div><!-- /nice-form__row -->

    </form><!-- /nice-form -->

</template>

<script>
    import vSelect from "vue-select";
    import axios from "axios";
    import "vue-select/src/scss/vue-select.scss";

    export default {
        name: 'FormContactUs',
        components: {
            vSelect,
        },
        data () {
            return {
                cooperationOptions: [
                    {
                        'label': 'blogger',
                        'value': 'blogger',
                    },
                    {
                        'label': 'webmaster',
                        'value': 'webmaster',
                    },
                ],
                OpenIndicator: {
                    render: createElement => createElement('span', {class: {'toggle': true}}),
                },
                name: null,
                email: null,
                user_type: null,
                question: null,
                errors: [],
                response: false,
                success: false,
                ru: false,
                formMessages: {},

                userTypeError: false,
                emptyNameError: false,
                incorrectNameError: false,
                emptyEmailError: false,
                incorrectEmailError: false,
                emptyQuestionError: false,
            }
        },
        methods: {
            setSelected(value) {
                this.clearErrors();
                return value;
            },
            checkForm() {
                this.errors = [];

                // name
                if (!this.name) {
                    this.errors.push(this.formMessages.emptyName);
                    this.emptyNameError = true;
                } else if (!/^[a-zA-Zа-яА-Я]+$/.test(this.name)) {
                    this.errors.push(this.formMessages.incorrectName);
                    this.incorrectNameError = true;
                }
                // email
                if (!this.email) {
                    this.errors.push(this.formMessages.emptyEmail);
                    this.emptyEmailError = true;
                } else if (!/\S+@\S+\.\S+/.test(this.email)) {
                    this.errors.push(this.formMessages.incorrectEmail);
                    this.incorrectEmailError = true;
                }
                // cooperation
                if (!this.user_type) {
                    this.errors.push(this.formMessages.emptyType);
                    this.userTypeError = true;
                }
                // question
                if (!this.question) {
                    this.errors.push(this.formMessages.emptyQuestion);
                    this.emptyQuestionError = true;
                }
            },
            clearErrors() {
                this.errors = [];

                this.userTypeError = false;
                this.emptyNameError = false;
                this.incorrectNameError = false;
                this.emptyEmailError = false;
                this.incorrectEmailError = false;
                this.emptyQuestionError = false;
            },
            clearForm() {
                this.name = null;
                this.user_type = null;
                this.email = null;
                this.question = null;
            },
            submit() {
                this.checkForm();
                if (!this.errors.length) {
                    axios.post('http://api.alpha.distribune.com/api/feedback', {
                        formName: 'Обратная связь',
                        name: this.name,
                        email: this.email,
                        user_type: this.user_type.value,
                        question: this.question,
                    }).then(response => {
                        this.response = true;
                        if (response.status === 200) {
                            this.success = true;
                            this.clearForm();
                            setTimeout(() => {this.response = false}, 3000);
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
                this.response = false;
                this.success = false;
            },
        },
        mounted () {
            this.$root.$i18n.locale === 'ru' ? this.ru = true : this.ru = false;
        }
    }
</script>

<style lang="scss" scoped>

</style>
