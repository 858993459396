<template>

    <div>

        <Header></Header>

        <section class="intro intro_3" v-bind:style='{ backgroundImage: "url("+ intro.IntroBGUrl +")" }' id="intro">

            <div class="site__centered">

                <h1 class="intro__title" v-html="$t('contentOwnersScreen.title')"></h1><!-- /intro__title -->

                <div class="site__btn">
                  <a :href="links.bloggerRegistration" class="btn" target="_blank">
                    {{ $t('buttons.signUp') }}
                  </a>
                </div><!-- /site__btn -->

            </div><!-- /site__centered -->

        </section><!-- /intro -->

        <section class="about-owner">

            <div class="site__centered">

                <site-title addedClass="site__title_duplicate">
                    {{$t('aboutOwner.title')}}
                </site-title>

                <div class="about-owner__wrap">

                    <div class="about-owner__item about-owner__item_1" v-html="$t('aboutOwner.item1')"></div><!-- /about-owner__item -->

                    <div class="about-owner__item about-owner__item_2" v-html="$t('aboutOwner.item2')"></div><!-- /about-owner__item -->

                    <div class="about-owner__item about-owner__item_3" v-html="$t('aboutOwner.item3')"></div><!-- /about-owner__item -->

                    <div class="about-owner__item about-owner__item_4" v-html="$t('aboutOwner.item4')"></div><!-- /about-owner__item -->

                    <div class="about-owner__item about-owner__item_5" v-html="$t('aboutOwner.item5')"></div><!-- /about-owner__item -->

                    <div class="about-owner__item about-owner__item_6" v-html="$t('aboutOwner.item6')"></div><!-- /about-owner__item -->

                </div><!-- /about-owner__wrap -->

                <div class="site__btn">
                  <a :href="links.bloggerRegistration" class="btn" target="_blank">
                    {{ $t('buttons.signUp') }}
                  </a>
                </div>

            </div><!-- /site__centered -->

        </section><!-- /about-owner -->

        <section class="owners-benefits">

            <div class="owners-benefits__title">

                <div class="site__centered">

                    <site-title addedClass="site__title_duplicate site__title_3">
                        {{$t('ownersBenefits.title')}}
                    </site-title>

                </div><!-- /site__centered -->

            </div><!-- /owners-benefits__title -->

            <div class="site__centered">

                <div class="owners-benefits__slider">

                    <div class="slider-3" ref="slider3">

                        <div class="swiper-container">

                            <div class="swiper-wrapper">

                                <div class="swiper-slide">

                                    <div class="slider-3__pic" v-bind:style='{ backgroundImage: "url("+ ownersBenefitsPics[0] +")" }'></div><!-- /slider-3__pic -->

                                    <div class="slider-3__txt" v-html="$t('ownersBenefits.item1')"></div><!-- /slider-3__txt -->

                                    <div class="slider-3__number">
                                        <span class="slider-3__number-item">1</span>
                                        <span class="slider-3__number-item"><span>4</span></span>
                                    </div><!-- /slider-2__number -->

                                </div><!-- /swiper-slide -->

                                <div class="swiper-slide">

                                    <div class="slider-3__pic" v-bind:style='{ backgroundImage: "url("+ ownersBenefitsPics[1] +")" }'></div><!-- /slider-3__pic -->

                                    <div class="slider-3__txt" v-html="$t('ownersBenefits.item2')"></div><!-- /slider-3__txt -->

                                    <div class="slider-3__number">
                                        <span class="slider-3__number-item">2</span>
                                        <span class="slider-3__number-item"><span>4</span></span>
                                    </div><!-- /slider-2__number -->

                                </div><!-- /swiper-slide -->

                                <div class="swiper-slide">

                                    <div class="slider-3__pic" v-bind:style='{ backgroundImage: "url("+ ownersBenefitsPics[2] +")" }'></div><!-- /slider-3__pic -->

                                    <div class="slider-3__txt" v-html="$t('ownersBenefits.item3')"></div><!-- /slider-3__txt -->

                                    <div class="slider-3__number">
                                        <span class="slider-3__number-item">3</span>
                                        <span class="slider-3__number-item"><span>4</span></span>
                                    </div><!-- /slider-2__number -->

                                </div><!-- /swiper-slide -->

                                <div class="swiper-slide">

                                    <div class="slider-3__pic" v-bind:style='{ backgroundImage: "url("+ ownersBenefitsPics[3] +")" }'></div><!-- /slider-3__pic -->

                                    <div class="slider-3__txt" v-html="$t('ownersBenefits.item4')"></div><!-- /slider-3__txt -->

                                    <div class="slider-3__number">
                                        <span class="slider-3__number-item">4</span>
                                        <span class="slider-3__number-item"><span>4</span></span>
                                    </div><!-- /slider-2__number -->

                                </div><!-- /swiper-slide -->

                            </div><!-- /swiper-wrapper -->

                        </div><!-- /swiper-container -->

                        <div class="swiper-pagination"></div>

                    </div><!-- /slider-3 -->

                </div><!-- /owners-benefits__slider -->

            </div><!-- /site__centered -->

            <div class="site__btn">
              <a :href="links.bloggerRegistration" class="btn" target="_blank">
                {{ $t('buttons.signUp') }}
              </a>
            </div><!-- /site__btn -->

        </section><!-- /owners-benefits -->

        <contact-us></contact-us>

        <Footer></Footer>

    </div>

</template>

<script>
    import Header from '../components/TheHeader';
    import Footer from '../components/TheFooter';
    import SiteTitle from "../components/SiteTitile";
    import ContactUs from "../components/ContactUs";
    import IntroBGUrl from "../assets/new/img/intro/intro__bg_3.jpg";
    import Swiper from "swiper";
    import "swiper/css/swiper.min.css";
    import ownersBenefitsPic1 from "../assets/new/pic/slider-3/slider-3__pic-1.jpg";
    import ownersBenefitsPic2 from "../assets/new/pic/slider-3/slider-3__pic-2.jpg";
    import ownersBenefitsPic3 from "../assets/new/pic/slider-3/slider-3__pic-3.jpg";
    import ownersBenefitsPic4 from "../assets/new/pic/slider-3/slider-3__pic-4.jpg";

    export default {
        name: 'ContentOwners',
        components: {
            Header,
            Footer,
            SiteTitle,
            ContactUs,
        },
        data () {
            return {
                intro: {
                    IntroBGUrl
                },
                ownersBenefitsPics: [
                    ownersBenefitsPic1,
                    ownersBenefitsPic2,
                    ownersBenefitsPic3,
                    ownersBenefitsPic4
                ],
                slider3: {
                    wrap: '',
                    container: '',
                    pagination: '',
                }
            }
        },
        methods: {
            setVariables () {
                this.slider3.wrap = this.$refs.slider3;
                this.slider3.container = this.slider3.wrap.querySelector('.swiper-container');
                this.slider3.pagination = this.slider3.wrap.querySelector('.swiper-pagination');
            },
            initSwiper () {
                this.setVariables();
                const mySwiper = new Swiper(this.slider3.container, {
                    slidesPerView: 1.4,
                    spaceBetween: 20,
                    freeMode: true,
                    pagination: {
                        el: this.slider3.pagination,
                        clickable: true,
                    },
                    breakpoints: {
                        420: {
                            slidesPerView: 1.8,
                        },
                        500: {
                            slidesPerView: 2,
                        },
                        600: {
                            slidesPerView: 2.5,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1600: {
                            slidesPerView: 3,
                            spaceBetween: 56,
                        },
                    }
                });
                mySwiper.init();
            }
        },
        computed: {
            links(){
                return this.$store.state.static.routePaths;
            }
        },
        mounted () {
            this.initSwiper();
        }
    }
</script>

<style lang="scss" scoped>

</style>
