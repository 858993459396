<template>

    <section class="contact-us contact-us_logo">

        <div class="site__centered">

            <div class="contact-us__wrap">

                <header class="contact-us__header">

                    <site-title addedClass="site__title_duplicate site__title_2">
                        {{$t('contactUs.title')}}
                    </site-title>

                    <p>{{$t('contactUs.text')}}</p>

                </header><!-- /contact-us__header -->

                <form-contact-us></form-contact-us>

            </div><!-- /contact-us__wrap -->

        </div><!-- /site__centered -->

    </section><!-- /contact-us -->

</template>

<script>
    import SiteTitle from "../components/SiteTitile";
    import FormContactUs from "../components/FormContactUs";
    export default {
        name: 'ContactUs',
        components: {
            SiteTitle,
            FormContactUs,
        },
    }
</script>